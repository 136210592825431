<template>
  <div>
    <CookareaCardSlideLg
      v-if="breakpoint.xlOnly || breakpoint.lgOnly || breakpoint.mdOnly"
      :card="card"
      :items-md="itemsMd"
    />
    <CookareaCardSlideSm v-if="breakpoint.smOnly" :card="card" :items-md="itemsMd" />
  </div>
</template>

<script>
/* eslint-disable */
import CookareaCardSlideLg from '../home/CookareaCardSlideLg.vue';
import CookareaCardSlideSm from '../home/CookareaCardSlideSm.vue';

export default {
  name: 'Pig',

  components: {
    CookareaCardSlideLg,
    CookareaCardSlideSm,
  },
  data() {
    return {
      itemsMd: [
        {
          img: 'tab-7-11.webp',
          imgCard: 'tab-7-1.webp',
        },
        {
          img: 'tab-7-12.webp',
          imgCard: 'tab-7-2.webp',
        },
        {
          img: 'tab-7-21.webp',
          imgCard: 'tab-7-3.webp',
        },
        {
          img: 'tab-7-22.webp',
          imgCard: 'tab-7-5.webp',
        },
        {
          img: 'tab-7-31.webp',
          imgCard: 'tab-7-4.webp',
        },
        {
          img: 'tab-7-32.webp',
          imgCard: 'tab-7-6.webp',
        },
      ],
      card: [
        {
          title: 'Pig',
          descriptionOne: 'Dimensions',
          descriptionTwo: 'Weight',
          sizeOne: '17x10x1 inches',
          sizeTwo: '3 pounds',
          tags: '#JuiceGrooves   #BigPrepArea #Cut&Serve   #JuiceGrooves #ErgonomicDesign   #Reversible   #OnFeetDrying',
        },
      ],
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
};
</script>

<style scoped lang="scss"></style>
