<template>
  <div class="product">
    <div class="product__page">/our product is a/</div>
    <div class="slide d-flex flex-row">
      <div class="card__left" style="width: 100%">
        <agile ref="slider" :autoplay="true" :dots="false" :nav-buttons="false" @after-change="afterChange">
          <div v-for="(product, i) in items" :key="i">
            <div class="product__gallery">
              <div class="product__gallery--title">{{ product.title }}</div>
              <div class="product__controls pt-3 d-flex justify-start">
                <CarouselControls :size="items.length" :current="currentSlide" @change="slideTo" />
              </div>
              <div class="product__gallery--imgOne"><img :src="`img/slide-img/${product.imgOne}`" width="100%" /></div>
              <div class="product__gallery--imgTwo"><img :src="`img/slide-img/${product.imgTwo}`" width="100%" /></div>
              <div class="product__gallery--imgThree">
                <img :src="`img/slide-img/${product.imgThree}`" width="100%" />
              </div>
            </div>
          </div>
        </agile>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile';
import CarouselControls from './CookareaBtnSlide';
/* eslint-disable */
export default {
  components: {
    agile: VueAgile,
    CarouselControls,
  },

  name: 'CookareaOurProduct',

  methods: {
    afterChange(current) {
      this.currentSlide = current.currentSlide;
    },
    slideTo(page) {
      this.$refs.slider.goTo(page);
    },
  },

  data() {
    return {
      currentSlide: 0,

      items: [
        {
          title: 'Holiday gift',
          imgOne: 'slide-1-1.webp',
          imgTwo: 'slide-1-2.webp',
          imgThree: 'slide-1-3.webp',
        },
        {
          title: 'Best kitchen assistant',
          imgOne: 'slide-4-1.webp',
          imgTwo: 'slide-4-2.webp',
          imgThree: 'slide-4-3.webp',
        },
        {
          title: 'Perfect food presentation',
          imgOne: 'slide-3-1.webp',
          imgTwo: 'slide-3-2.webp',
          imgThree: 'slide-3-3.webp',
        },
        {
          title: 'Functional decor piece',
          imgOne: 'slide-2-1.webp',
          imgTwo: 'slide-2-2.webp',
          imgThree: 'slide-2-3.webp',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.product {
  padding: 50px 100px 50px 100px;
  font-family: 'SangBleu Sunrise';
  font-style: normal;
  font-weight: 400;
  color: #4c4f6b;

  &__page {
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    padding-bottom: 10px;
    position: absolute;
  }
  &__gallery {
    position: relative;

    display: grid;
    grid-template-areas:
      'T T T T D D D D'
      'C C . . D D D D'
      'A A B B D D D D';

    grid-template-rows: 14% 11% 70%;
    grid-template-columns: 12% 12% 12% 12% 13% 13% 13% 13%;
    align-items: flex-end;

    &--title {
      font-size: 34px;
      line-height: 56px;

      text-transform: uppercase;
      grid-area: T;
      padding: 4px 0;
    }
    &--imgOne {
      position: absolute;
      bottom: 0;
      grid-area: A;
      margin-right: 30px;
    }
    &--imgTwo {
      position: absolute;
      bottom: 0;
      grid-area: B;
      margin-right: 30px;
    }
    &--imgThree {
      grid-area: D;
    }
    @media (max-width: 1406px) {
      &--title {
        font-size: 32px;
        line-height: 1px;
        position: absolute;
        padding: 23px 0;
      }
    }

    @media (max-width: 1335px) {
      &--title {
        font-size: 30px;
      }
    }
    @media (max-width: 1270px) {
      &--title {
        font-size: 29px;
      }
    }

    @media (max-width: 1240px) {
      &--title {
        font-size: 27px;
        line-height: 1px;
        position: absolute;
      }
    }
    @media (max-width: 1150px) {
      &--title {
        font-size: 21px;
        padding: 15px 0;
      }
    }
  }
  &__controls {
    grid-area: C;
    position: absolute;
    top: 5%;
  }

  @media (max-width: 960px) {
    padding: 25px 50px 25px 50px;

    &__page {
      font-size: 12px;
      line-height: 18px;
    }

    &__gallery {
      &--title {
        font-size: 20px;
        line-height: 1px;
        position: absolute;
        padding: 17px 0;
      }
      &--imgOne {
        margin-right: 20px;
      }
      &--imgTwo {
        margin-right: 20px;
      }
    }
    &__controls {
      position: absolute;
      top: 5%;
    }
  }
  @media (max-width: 830px) {
    &__gallery {
      &--title {
        font-size: 17px;

        padding: 13px 0;
      }
    }
  }
  @media (max-width: 700px) {
    &__gallery {
      &--title {
        font-size: 14px;

        padding: 13px 0;
      }
    }
  }
}
</style>
