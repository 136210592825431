<template>
  <div class="home">
    <div class="home__content">
      <div
        v-if="breakpoint.xlOnly || breakpoint.lgOnly || breakpoint.mdOnly || breakpoint.smOnly"
        class="home__content--header d-flex justify-space-between"
      >
        <div class="d-flex home__content--img">
          <a href="https://www.amazon.com/gp/product/B0BDSRRPYM" target="_blank">
            <v-img
              class="mr-6 home__content--svg"
              style="backgraund-color: #4c4f6b; margin-top: 1px"
              src="img/svg/amazon-foot.svg"
            ></v-img
          ></a>
          <v-img class="mr-6 home__content--svg" src="img/svg/instagram-foot.svg"></v-img>
          <v-img class="mr-6 home__content--svg" src="img/svg/facebook-foot.svg"></v-img>
        </div>
        <div class="home__content--text">The area of inspiration</div>
        <v-btn style="background-color: #8287b8" class="home__content--btn" @click="onHeaderClicked"
          >Horeca/order</v-btn
        >
      </div>

      <v-btn
        v-if="breakpoint.xlOnly || breakpoint.lgOnly || breakpoint.mdOnly || breakpoint.smOnly"
        text
        color="white"
        class="font-weight-bold home__content--home text-capitalize px-2 py-0"
        style="text-transform: uppercase"
        @click="$router.back()"
        ><v-icon color="white">mdi-arrow-left</v-icon>Back</v-btn
      >
      <div class="d-flex justify-center align-center flex-column home__content--title">
        <div class="home__content--about mb-2"><v-img src="img/svg/about.svg"></v-img></div>

        <div class="home__content--cook"><v-img src="img/svg/cookarea.svg"></v-img></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'CookareaHome',

  data() {
    return {};
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
  methods: {
    onHeaderClicked(form) {
      this.$router.push('/#form-contact');
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'rec1';

  &__content {
    background-color: #c0c3dc;
    height: 100%;
    grid-area: rec1;
    width: 100%;
    z-index: 1;

    &--img {
      margin-left: 100px;
    }

    &--header {
      height: 70px;
      background-color: rgba(255, 255, 255, 0.2);
      align-items: center;
      background-color: white;
    }

    &--text {
      font-family: 'SangBleu Sunrise';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      text-transform: uppercase;
      color: #4c4f6b;
    }

    &--btn {
      margin-right: 100px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      border-radius: 70px;
    }

    &--title {
      margin-bottom: 180px;
      margin-top: 159px;
    }

    &--cook {
      width: 396px;
    }
    &--home {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      margin-left: 100px;
      margin-top: 15px;

      color: #4c4f6b;
    }
    &--about {
      margin-top: 15px;
    }

    &--svg {
      width: 24px;
      height: 24px;
    }

    @media (max-width: 960px) {
      &--title {
        margin-bottom: 80px;
        margin-top: 51px;
      }
      &--header {
        height: 54px;
      }
      &--img {
        margin-left: 50px;
      }
      &--home {
        margin-left: 10px;
        margin-top: 10px;
        width: 170px;
        padding: 0px !important;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        text-transform: uppercase !important;
      }
      &--about {
        margin-top: 10px;
        width: 170px;
        padding: 0px !important;
      }

      &--cook {
        width: 262px;
      }
      &--btn {
        margin-right: 50px;
        font-size: 14px;
        line-height: 17px;
        height: 34px !important;
      }
      &--text {
        font-size: 12px;
        line-height: 18px;
      }

      &--svg {
        width: 18px;
        height: 18px;
      }
    }
    @media (max-width: 600px) {
      &--img {
        margin-left: 15px;
      }
      &--header {
        height: 48px;
      }
      &--home {
        margin-left: 15px;
        margin-top: 5px;
        width: 80px;
        text-transform: uppercase !important;
        background-color: none;
      }
      &--about {
        margin-top: 5px;
        width: 100px;
      }
      &--cook {
        width: 144px;
      }
      &--btn {
        margin-right: 15px;
      }
      &--title {
        margin-bottom: 90px;
        margin-top: 90px;
      }
    }
  }
}
</style>
