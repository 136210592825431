<template>
  <div class="form">
    <v-row class="d-flex">
      <v-col cols="12" lg="5" md="5" sm="5" xs="12" class="form__title pa-4 pb-1"
        >Want to order our items or contact us?
        <br />
        <div>
          Fill the form and click the “Send” button below and one of the COOKAREA inspo Customer Services team will be
          in touch
        </div></v-col
      >

      <v-col cols="12" lg="7" md="7" sm="7" xs="12">
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="name"
            dense
            color="#4c4f6bb5"
            outlined
            background-color="#C0C3DC"
            class="form__input rules"
            label="Name"
            :rules="[rules.required('Enter name')]"
          />
          <v-text-field
            v-model="phone"
            dense
            outlined
            color="#4c4f6bb5"
            background-color="#C0C3DC"
            class="form__input"
            label="Phone"
            :rules="[rules.required('Enter phone')]"
          />
          <v-text-field
            v-model="email"
            dense
            outlined
            color="#4c4f6bb5"
            background-color="#C0C3DC"
            class="form__input"
            label="Email"
            :rules="[rules.required('Enter E-mail'), rules.email]"
          />

          <v-textarea
            v-model="comment"
            dense
            background-color="rgb(192, 195, 220)"
            color="#4c4f6bb5"
            class="comment mb-0 pb-0"
            label="Comment"
            auto-grow
            outlined
            rows="3"
            row-height="25"
          ></v-textarea>

          <div class="d-flex justify-center form__div">
            <v-btn
              v-if="breakpoint.xlOnly || breakpoint.lgOnly || breakpoint.mdOnly || breakpoint.smOnly"
              class="form__btn"
              :disabled="!valid"
              @click="sendForm"
            >
              SEND
            </v-btn>

            <v-btn v-if="breakpoint.xsOnly" color="#8287b8" style="width: 100%" :disabled="!valid" @click="sendForm"
              >SEND</v-btn
            >
          </div>

          <v-dialog v-model="dialog" width="250">
            <v-card>
              <v-card-text class="pt-3 pb-0 form__dialog"> Your form has been submitted successfully! </v-card-text>

              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false"> Ok </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import { patternEmail } from '@/utils/helpers';
import axios from 'axios';

export default {
  name: 'CookareaForm',

  data() {
    return {
      name: null,
      phone: null,
      email: null,
      comment: null,
      dialog: false,
      valid: false,
      rules: {
        required: (msg) => (v) => !!v || msg,
        email: (v) => patternEmail.test(v) || 'Invalid e-mail',
      },
    };
  },
  methods: {
    clearForm() {
      this.name = null;
      this.phone = null;
      this.email = null;
      this.comment = null;
    },
    async sendForm() {
      const config = {
        url: 'https://formspree.io/f/moqygzzq',
        method: 'post',
        data: {
          name_input: this.name,
          email_input: this.email,
          phone_input: this.phone,
          comment: this.comment,
        },
      };
      axios.defaults.withCredentials = false;

      const res = await axios(config);

      if (res) {
        this.clearForm();
        this.$refs.form.resetValidation();
        this.dialog = true;

        setTimeout(() => {
          this.dialog = false;
        }, 3000);
      }
    },
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
};
</script>

<style lang="scss">
.form {
  padding: 50px 100px 100px 100px;
  font-family: 'SangBleu Sunrise';
  font-style: normal;

  &__title {
    font-size: 28px;
    line-height: 41px;

    color: #4c4f6b;
  }

  &__input {
    height: 62px !important;
  }

  &__btn {
    background-color: #8287b8 !important;
    backdrop-filter: blur(7px) !important;
    font-family: 'Inter';
    font-size: 18px;
    border-radius: 70px;
    width: 253px;
  }
  @media (max-width: 1240px) {
    &__title {
      font-size: 24px;
    }
  }
  @media (max-width: 960px) {
    padding: 25px 50px 50px 50px;
    &__title {
      font-size: 14px;
      line-height: 19px;
    }
  }

  @media (max-width: 600px) {
    padding: 25px 15px 50px 15px;
  }
}

.v-text-field fieldset {
  border: none !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 50px;
}
.theme--light.v-btn {
  color: white;
}
.v-text-field .v-input__slot {
  border-radius: 3px !important;
}

.theme--light.v-input input {
  color: white !important;
}

.v-text-field--outlined.v-input--dense .v-label {
  color: aliceblue !important;
}

.v-text-field--outlined.v-input--dense .v-label--active {
  color: #4c4f6bb5 !important;
}

.theme--light.v-input:not(.v-input--is-disabled) input,
.theme--light.v-input:not(.v-input--is-disabled) textarea {
  color: white !important;
}

.theme--light.v-btn.v-btn--disabled {
  color: rgb(76 79 107 / 45%) !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
