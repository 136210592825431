<template>
  <div class="mob">
    <div class="mob__title mb-4">Get to know our products</div>

    <ScrollShadow>
      <div class="d-flex">
        <div class="d-flex mb-3">
          <CookareaCardSlideMobile :items="itemsCatTray" :card="cardCatTray" :title="titleCatTray" class="mr-2" />
          <CookareaCardSlideMobile :items="itemsHeartCat" :card="cardHeartCat" :title="titleHeartCat" class="mr-2" />
          <CookareaCardSlideMobile :items="itemsHeartDog" :card="cardHeartDog" :title="titleHeartDog" class="mr-2" />
          <CookareaCardSlideMobile :items="itemsDogTray" :card="cardDogTray" :title="titleDogTray" class="mr-2" />
          <CookareaCardSlideMobile :items="itemsBull" :card="cardBull" :title="titleBull" class="mr-2" />
          <CookareaCardSlideMobile :items="itemsBear" :card="cardBear" :title="titleBear" class="mr-2" />

          <CookareaCardSlideMobile :items="itemsPig" :card="cardPig" :title="titlePig" />
        </div>
      </div>
    </ScrollShadow>
  </div>
</template>

<script>
import CookareaCardSlideMobile from './CookareaCardSlideMobile';
import ScrollShadow from '../ScrollShadow.vue';

/* eslint-disable */
export default {
  components: {
    CookareaCardSlideMobile,
    ScrollShadow,
  },

  name: 'CookareaCardMainMobile',

  data() {
    return {
      titleBear: 'Bear',
      itemsBear: [
        {
          img: 'tab-6-1.webp',
        },
        {
          img: 'tab-6-2.webp',
        },
        {
          img: 'tab-6-3.webp',
        },
        {
          img: 'tab-6-4.webp',
        },
      ],
      cardBear: [
        {
          descriptionOne: 'Dimensions',
          descriptionTwo: 'Weight',
          sizeOne: '16x10.5x1 inches',
          sizeTwo: '2.8 pounds',
          tags: '#JuiceGrooves   #BigPrepArea #Cut&Serve   #JuiceGrooves #ErgonomicDesign   #Reversible   #OnFeetDrying',
        },
      ],
      titleBull: 'Bull',
      cardBull: [
        {
          descriptionOne: 'Dimensions',
          descriptionTwo: 'Weight',
          sizeOne: '12x11x1 inches',
          sizeTwo: '1.7 pounds',
          tags: '#IdealForSteaks   #SauceCompartments  #JuiceGrooves #ErgonomicDesign   #Reversible   #OnFeetDrying',
        },
      ],
      itemsBull: [
        {
          img: 'tab-5-1.webp',
        },
        {
          img: 'tab-5-2.webp',
        },
        {
          img: 'tab-5-3.webp',
        },
        {
          img: 'tab-5-4.webp',
        },
        {
          img: 'tab-5-5.webp',
        },
        {
          img: 'tab-5-6.webp',
        },
      ],
      titleCatTray: 'Cat tray',
      cardCatTray: [
        {
          title: 'Cat tray',
          descriptionOne: 'Dimensions',
          descriptionTwo: 'Weight',
          sizeOne: '12x10x1 inches',
          sizeTwo: '1.9 pounds',
          tags: '#IdealForCarryingAndServing   #InnerRecessWithSides   #ErgonomicDesign   #Reversible   #OnFeetDrying',
        },
      ],
      itemsCatTray: [
        {
          img: 'tab-1-1.webp',
        },
        {
          img: 'tab-1-2.webp',
        },
        {
          img: 'tab-1-3.webp',
        },
        {
          img: 'tab-1-4.webp',
        },
      ],
      titleDogTray: 'Dog tray',
      cardDogTray: [
        {
          descriptionOne: 'Dimensions',
          descriptionTwo: 'Weight',
          sizeOne: '13x9x1 inches',
          sizeTwo: '1.9 pounds',
          tags: '#IdealForCarryingAndServing   #InnerRecessWithSides  #SauceCompartments #ErgonomicDesign   #Reversible   #OnFeetDrying',
        },
      ],
      itemsDogTray: [
        {
          img: 'tab-3-1.webp',
        },
        {
          img: 'tab-3-2.webp',
        },
        {
          img: 'tab-3-3.webp',
        },
        {
          img: 'tab-3-4.webp',
        },
        {
          img: 'tab-3-5.webp',
        },
        {
          img: 'tab-3-7.webp',
        },
      ],
      titleHeartCat: 'Heart cat',
      cardHeartCat: [
        {
          descriptionOne: 'Dimensions',
          descriptionTwo: 'Weight',
          sizeOne: '10x10x1 inches',
          sizeTwo: '1.8 pounds',
          tags: '##Functional prep area  #ErgonomicDesign   #Reversible   #OnFeetDrying',
          href: 'https://www.amazon.com/gp/product/B0BFP54SSW',
        },
      ],
      itemsHeartCat: [
        {
          img: 'tab-2-1.webp',
        },
        {
          img: 'tab-2-2.webp',
        },

        {
          img: 'tab-2-3.webp',
        },

        {
          img: 'tab-2-4.webp',
        },
      ],

      titleHeartDog: 'Heart dog',
      cardHeartDog: [
        {
          descriptionOne: 'Dimensions',
          descriptionTwo: 'Weight',
          sizeOne: '11x9x1 inches',
          sizeTwo: '1.8 pounds',
          tags: '#FunctionalPrepArea  #JuiceGrooves #ErgonomicDesign   #Reversible   #OnFeetDrying',
          href: 'https://www.amazon.com/gp/product/B0BDSRRPYM',
        },
      ],

      titlePig: 'Pig',
      cardPig: [
        {
          descriptionOne: 'Dimensions',
          descriptionTwo: 'Weight',
          sizeOne: '16x10x1 inches',
          sizeTwo: '3 pounds',
          tags: '#JuiceGrooves   #BigPrepArea #Cut&Serve   #JuiceGrooves #ErgonomicDesign   #Reversible   #OnFeetDrying',
        },
      ],
      itemsPig: [
        {
          img: 'tab-7-1.webp',
        },
        {
          img: 'tab-7-2.webp',
        },
        {
          img: 'tab-7-3.webp',
        },
        {
          img: 'tab-7-4.webp',
        },
        {
          img: 'tab-7-5.webp',
        },
        {
          img: 'tab-7-6.webp',
        },
      ],
      itemsHeartDog: [
        {
          img: 'tab-4-1.webp',
        },
        {
          img: 'tab-4-2.webp',
        },
        {
          img: 'tab-4-3.webp',
        },
        {
          img: 'tab-4-4.webp',
        },
        {
          img: 'tab-4-5.webp',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.showscrollbar {
  overflow-x: scroll;
  overflow-y: hidden;
}

.showscrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
}

.showscrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.mob {
  padding: 25px 15px 25px 15px;

  &__title {
    font-family: 'SangBleu Sunrise';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 41px;
    text-transform: uppercase;
    color: #4c4f6b;
  }
}
</style>
