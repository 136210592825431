<template>
  <div style="background-color: #f7f7f7">
    <div v-if="breakpoint.xsOnly && $route.path != '/about'" class="header d-flex justify-center">
      <div class="header__text d-flex justify-center">The area of inspiration</div>
    </div>
    <CookareaHome />
    <div v-if="breakpoint.xsOnly" class="header__order">
      <v-btn color="#8287b8" width="100%" class="header__btn mt-3" @click="onHeaderClicked('form-contact')"
        >Horeca/order</v-btn
      >
      <v-btn color="#8287b8" width="100%" class="header__btn mt-3" @click="$router.push('about')">about brand</v-btn>
      <v-btn
        color="#8287b8"
        width="100%"
        class="header__btn mt-3"
        href="https://www.amazon.com/gp/product/B0BDSRRPYM"
        target="_blank"
        >Buy on amazon</v-btn
      >
    </div>
    <CookareaShowcase />
    <CookareaBenefitsLg v-if="breakpoint.xlOnly || breakpoint.lgOnly" />
    <CookareaBenefitsMdSm v-if="breakpoint.mdOnly || breakpoint.smOnly" />
    <CookareaBenefitsXs v-if="breakpoint.xsOnly" />
    <CookareaMaterials />
    <CookareaSteps />
    <CookareaCardTab v-if="breakpoint.xlOnly || breakpoint.lgOnly || breakpoint.mdOnly || breakpoint.smOnly" />

    <CookareaCardMainMobile v-if="breakpoint.xsOnly" />
    <CookareaOurProduct v-if="breakpoint.xlOnly || breakpoint.lgOnly || breakpoint.mdOnly || breakpoint.smOnly" />
    <CookareaOurProductMobile v-if="breakpoint.xsOnly" />
    <CookareaForm id="form-contact" />
    <CookareaFooter />
  </div>
</template>

<script>
/* eslint-disable */
import CookareaHome from './home/CookareaHome';
import CookareaShowcase from './home/CookareaShowcase';
import CookareaForm from './home/CookareaForm';
import CookareaFooter from './home/CookareaFooter';
import CookareaMaterials from './home/CookareaMaterials';
import CookareaOurProduct from './home/CookareaOurProduct';
import CookareaSteps from './home/CookareaSteps';
import CookareaCardTab from './home/CookareaCardTab';
import CookareaCardMainMobile from './home/CookareaCardMainMobile';
import CookareaBenefitsLg from './home/CookareaBenefitsLg';
import CookareaBenefitsMdSm from './home/CookareaBenefitsMdSm';
import CookareaBenefitsXs from './home/CookareaBenefitsXs';
import CookareaOurProductMobile from './home/CookareaOurProductMobile';

export default {
  name: 'Home',

  components: {
    CookareaHome,
    CookareaShowcase,
    CookareaCardTab,
    CookareaCardMainMobile,
    CookareaForm,
    CookareaFooter,
    CookareaMaterials,
    CookareaOurProduct,
    CookareaSteps,
    CookareaBenefitsLg,
    CookareaOurProductMobile,
    CookareaBenefitsMdSm,
    CookareaBenefitsXs,
  },

  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },

  methods: {
    onHeaderClicked(form) {
      document.getElementById(form).scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  background-color: white;
  height: 58px;
  align-items: center;
  &__text {
    font-family: 'SangBleu Sunrise';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #4c4f6b;
    align-items: center;
  }

  &__order {
    margin-left: 15px;
    margin-right: 15px;
  }

  &__btn {
    background-color: #8287b8;
    border-radius: 3px;
    font-family: 'Inter';

    height: 40px !important;
    font-size: 14px !important;
    line-height: 17px;
    font-style: normal;
    font-weight: 700 !important;
  }
}
</style>
