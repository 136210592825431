<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
/* eslint-disable */

export default {
  name: 'App',
  data: () => ({}),
};
</script>
<style lang="scss">
@import './styles/main';
</style>
