<template>
  <div class="about">
    <div class="about__text about__one mb-lg-12 mb-md-12 mb-sm-12 mt-5">
      Cookarea inspo is the brand for people who are united by inspiration in the process of cooking.
      <br />
      We create products that help in the preparation and presentation of food, its serving, decorating your kitchen and
      home: kitchen utensils, dishes, decor and interior items, kitchen and home textiles, furniture and storage
      systems.
    </div>

    <v-row v-for="(data, i) in about" :key="i" class="d-flex">
      <v-col
        v-if="data.imgLeft"
        :cols="data.cols"
        :lg="data.lgImg"
        :md="data.mdImg"
        :sm="data.smImg"
        :xs="data.xsImg"
        class="align-self-center mb-lg-7 mb-md-7 mb-sm-7 mb-xs-1"
      >
        <div><img :src="`./img/${data.imgLeft}`" width="100%" class="pr-lg-7 pr-md-6 pr-sm-2 pr-xs-0" /></div>
      </v-col>

      <v-col
        v-if="data.imgRight && breakpoint.xsOnly"
        :cols="data.cols"
        :lg="data.lgImg"
        :md="data.mdImg"
        :sm="data.smImg"
        :xs="data.xsImg"
        class="align-self-center mb-lg-7 mb-md-7 mb-sm-7 mb-xs-1"
      >
        <div>
          <div v-if="data.title" class="about__title mb-2">
            {{ data.title }}
          </div>
          <img :src="`./img/${data.imgRight}`" width="100%" class="pl-lg-7 pl-md-6 pl-sm-2 pl-xs-0" />
        </div>
      </v-col>

      <v-col
        :cols="data.cols"
        :lg="data.lg"
        :md="data.md"
        :sm="data.sm"
        :xs="data.xs"
        :class="data.isSelf ? '' : 'align-self-center'"
        class="mb-lg-7 mb-md-7 mb-sm-7 mb-xs-1"
      >
        <div class="about__text">{{ data.text }}</div>
        <div class="about__text pl-lg-7 pl-md-6 pl-sm-2 pl-xs-0">
          {{ data.textRight }}
        </div>

        <div
          v-if="data.title && (breakpoint.xlOnly || breakpoint.lgOnly || breakpoint.mdOnly || breakpoint.smOnly)"
          class="about__title mb-11"
        >
          {{ data.title }}
        </div>
        <div class="about__text">
          <div class="mb-2">{{ data.paragraphOne }}</div>
          <div class="mb-2">
            {{ data.paragraphTwo }}
          </div>
          <div class="mb-2">
            {{ data.paragraphThree }}
          </div>
          <div class="mb-2">
            {{ data.paragraphFour }}
          </div>
        </div>
      </v-col>

      <v-col
        v-if="data.imgRight && (breakpoint.xlOnly || breakpoint.lgOnly || breakpoint.mdOnly || breakpoint.smOnly)"
        :cols="data.cols"
        :lg="data.lgImg"
        :md="data.mdImg"
        :sm="data.smImg"
        :xs="data.xsImg"
        :class="data.isSelf ? '' : 'align-self-center'"
        class="mb-lg-7 mb-md-7 mb-sm-7 mb-xs-1"
      >
        <div><img :src="`./img/${data.imgRight}`" width="100%" class="pl-lg-7 pl-md-6 pl-sm-2 pl-xs-0" /></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'CookareaAbout',

  data() {
    return {
      about: [
        {
          text: 'Cookarea inspo is an American brand with an international team, its own product development office and partner production sites. Creating our products we use unique design solutions, comply with the environmentally friendly production and processing technologies with only high-quality and green materials. We also help nature and animals from selling our goods.',
          imgRight: 'about-1.webp',
          cols: 12,
          lg: 4,
          md: 6,
          sm: 6,
          xs: 12,
          lgImg: 8,
          mdImg: 6,
          smImg: 6,
          xsImg: 12,
        },
        {
          textRight:
            'We believe it’s very important to enjoy not only the food itself, but also the process and the items involved in it. Be inspired by the aesthetic pleasure of style, shapes, accents, combinations and sensations of our essentials when you not only look at them, but also hold in your hands.',
          imgLeft: 'about-2.webp',
          cols: 12,
          lg: 4,
          md: 6,
          sm: 6,
          xs: 12,
          lgImg: 8,
          mdImg: 6,
          smImg: 6,
          xsImg: 12,
        },
        {
          text: 'We love to experiment and create new products, and therefore we support initiatives in every possible way. Our products are designed to implement the most challenging ideas and help bring them to life from the simplest items to the masterpieces.',
          imgRight: 'about-3.webp',
          cols: 12,
          lg: 4,
          md: 6,
          sm: 6,
          xs: 12,
          lgImg: 8,
          mdImg: 6,
          smImg: 6,
          xsImg: 12,
        },
        {
          textRight:
            'We are absolutely convinced that your home comfort and convenience, functionality of everything that is involved in cooking, details focusing are crucial for enjoying throughout the entire process from idea to serving.',
          imgLeft: 'about-4.webp',
          cols: 12,
          lg: 4,
          md: 6,
          sm: 6,
          xs: 12,
          lgImg: 8,
          mdImg: 6,
          smImg: 6,
          xsImg: 12,
        },
        {
          text: 'We strive to make an inviting atmosphere in your home filling every house with warmth, harmony, freedom, desire and inspiration. The energy of nature and green products definitely help us with it.',
          imgRight: 'about-5.webp',
          cols: 12,
          lg: 4,
          md: 6,
          sm: 6,
          xs: 12,
          lgImg: 8,
          mdImg: 6,
          smImg: 6,
          xsImg: 12,
        },
        {
          title: 'Something else about our products',
          paragraphOne: '-Full production cycle and quality control at every stage.',
          paragraphTwo:
            '-We do use manual labor. At least 60% of the production time is handcrafted which brings the master’s warmth and energy to each product.',
          paragraphThree: '-Green production. We minimize the amount of waste and use only natural oil and wax.',
          paragraphFour: '- We take care of nature and animals donating to green funds for each product we sell.',
          imgRight: 'about-6.webp',
          cols: 12,
          lg: 6,
          md: 6,
          sm: 6,
          xs: 12,
          lgImg: 6,
          mdImg: 6,
          smImg: 6,
          xsImg: 12,
          isSelf: true,
        },
      ],
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
};
</script>

<style lang="scss">
.about {
  font-family: 'SangBleu Sunrise';
  font-style: normal;
  font-weight: 400;
  color: #4c4f6b;
  padding: 50px 100px 50px 100px;
  padding-top: 50px;

  &__title {
    font-size: 38px;
    line-height: 56px;
    text-transform: uppercase;
  }

  &__one {
    margin-bottom: 40px;
  }
  &__text {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: 1240px) {
    &__title {
      font-size: 18px;
      line-height: 26px;
    }
  }
  @media (max-width: 960px) {
    padding: 25px 50px 25px 50px;
    font-size: 12px;
    line-height: 18px;

    &__title {
      font-size: 18px;
      line-height: 26px;
    }

    &__text {
      font-size: 12px;
      line-height: 18px;
    }
  }
  @media (max-width: 600px) {
    padding: 25px 15px 38px 15px;

    &__text {
      font-size: 14px;
      line-height: 19px;
    }
  }
}
</style>
