<template>
  <div class="md">
    <v-row class="d-flex justify-space-between md__row mt-0">
      <v-col cols="6" class="md__page pa-0">Benefits</v-col>
    </v-row>

    <v-row class="md__row">
      <v-col class="pa-0 md__col d-flex justify-center flex-column">
        <div class="md__smart">Smart ergonomics in an exclusive design</div>
        <div class="md__text">
          <div class="mb-2">We have created natural art design and beauty considering the most usable surface area</div>
          <div class="mb-2">
            For ease of use, we have designed optimal thickness and weight parameters. Our kitchen essentials are
            pleasant to hold and easy to wash. We have also thought about serving dishes with sauces and a juice groove
            side
          </div>
          <div class="mb-2">
            Fast drying and best storage – our cutting board can stand on its feet or a flat surface without a holder.
            Just put it on a cloth after washing and the board dries in 5 minutes and turns into an art objects
          </div>
        </div>
      </v-col>
      <v-col class="pa-0 d-flex justify-center flex-column">
        <div class="md__text md__text--col">
          We love to cook and spend time in the kitchen and we understand how important every item around us is in
          creating an atmosphere of inspiration and comfort, and how functional and safe it should be.
        </div>
        <div class="d-flex" style="line-height: 0">
          <img src="img/ben-1.webp" width="100%" height="100%" style="overflow: hidden" class="md__img" />
          <img src="img/ben-2.webp" width="100%" height="100%" style="overflow: hidden" />
        </div>
      </v-col>
    </v-row>

    <v-row class="md__row mt-0">
      <v-col class="pa-0 md__col d-flex justify-center flex-column">
        <div style="line-height: 0">
          <img src="img/ben-3.webp" width="100%" style="overflow: hidden" />
        </div>
      </v-col>
      <v-col class="pa-0 d-flex justify-center flex-column">
        <div class="md__smart">Practical and multifunctional</div>
        <div class="md__text">
          <div class="mb-2">
            Cut different foods and serve hot dishes, cured meats or pâtés, as well as cheeses and crackers or bread.
            You can eat straight off the boards, and they are always ready for any of your experiments.
          </div>
          <div class="mb-2">
            Decorate your table, kitchen and dining room. Add accents to your home interior with our
            animal-shapedboards.
          </div>
          <div class="mb-2">
            There is no need to have loads of various boards – you can have just one cute and loyal friend.
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col class="pa-0 md__col d-flex justify-center flex-column">
        <div class="md__smart">Safe</div>
        <div class="md__text mb-4">
          <div class="mb-2">
            The boards are made of solid premium European oak. This antibacterial wood is ideal for a long-term
            ownership and passing down through generations.
          </div>
          <div class="mb-2">We cover the wood only with natural products without chemical compounds and dyes.</div>
          <div>We use fully recyclable eco-friendly packaging, without colored paints, plastic and foam rubber.</div>
        </div>

        <v-btn text color="#4c4f6b" class="md__btn font-weight-bold pa-0" @click="$router.push('about')"
          >About brand<v-icon color="#4c4f6b">mdi-arrow-right</v-icon></v-btn
        >
      </v-col>
      <v-col class="pa-0 d-flex justify-center flex-column">
        <div style="line-height: 0">
          <img src="img/ben-4.webp" width="100%" style="overflow: hidden" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'CookareaBenefits',
};
</script>

<style lang="scss">
.md {
  padding: 50px 100px 50px 100px;
  font-family: 'SangBleu Sunrise';
  font-style: normal;
  font-weight: 400;
  color: #4c4f6b;

  &__row {
    margin-bottom: 50px;
  }
  &__col {
    margin-right: 30px;
  }
  &__page {
    font-size: 38px;
    line-height: 56px;
    text-transform: uppercase;
  }

  &__img {
    margin-right: 30px;
  }

  &__text {
    font-size: 18px;
    line-height: 25px;

    &--col {
      margin-bottom: 30px;
    }
  }

  &__smart {
    font-size: 28px;
    line-height: 41px;
    margin-bottom: 20px;
  }

  &__btn {
    font-size: 18px !important;
    line-height: 26px;
    width: 200px;
    text-transform: uppercase;
    background-color: #c0c3dc47;
    border-radius: 10px;
  }

  @media (max-width: 960px) {
    padding: 25px 50px 25px 50px;

    &__img {
      margin-right: 15px;
    }

    &__text {
      font-size: 12px;
      line-height: 18px;

      &--col {
        margin-bottom: 15px;
      }
    }
    &__page {
      font-size: 28px;
      line-height: 41px;
    }
    &__smart {
      font-size: 18px;
      line-height: 26px;
    }
    &__btn {
      width: 170px;
      font-size: 14px !important;
      line-height: 21px;
    }
  }
}
</style>
