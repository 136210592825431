<template>
  <v-card class="d-flex flex-column pt-5 mr-2" max-width="322" min-height="510">
    <div class="flex-column" style="flex-grow: 1">
      <div class="mob__tit">{{ title }}</div>
      <agile
        ref="sliderMobile"
        :dots="false"
        :nav-buttons="false"
        style="background: white"
        @after-change="afterChange"
      >
        <div v-for="(item, i) of items" :key="i" class="px-7 d-flex justify-center">
          <v-img :src="`img/card/${item.img}`" width="95%" height="95%" style="background: white" />
        </div>
      </agile>
      <CarouselBtn
        class="justify-center mt-3 mob__btn"
        :size="items.length"
        :current="currentSlide"
        @change="slideTo"
      />
    </div>

    <div class="px-2 py-0 pb-6 d-flex flex-column">
      <div v-for="(data, i) in card" :key="i">
        <div class="d-flex justify-center">
          <div class="mr-5">
            <div class="mob__description text--disabled">{{ data.descriptionOne }}</div>
            <div class="mob__size">{{ data.sizeOne }}</div>
          </div>
          <div>
            <div class="mob__description text--disabled">{{ data.descriptionTwo }}</div>
            <div class="mob__size">{{ data.sizeTwo }}</div>
          </div>
        </div>
        <div class="mob__tags text--disabled px-10">{{ data.tags }}</div>
        <div class="d-flex justify-center">
          <v-btn
            text
            color="#4c4f6b"
            class="mob__amazon font-weight-bold pa-0"
            :href="data.href ? data.href : null"
            target="_blank"
            >Buy on amazon</v-btn
          >
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { VueAgile } from 'vue-agile';
import CarouselBtn from './CookareaBtnSlide';

/* eslint-disable */
export default {
  components: {
    agile: VueAgile,
    CarouselBtn,
  },

  props: {
    card: Array,
    title: String,
    items: Array,
  },
  name: 'CookareaCardSlideMobile',

  data() {
    return {
      currentSlide: 0,
    };
  },

  methods: {
    afterChange(current) {
      this.currentSlide = current.currentSlide;
    },
    slideTo(page) {
      this.$refs.sliderMobile.goTo(page);
    },
  },
};
</script>

<style lang="scss">
.agile {
  width: 100%;
}

.mob {
  font-family: 'SangBleu Sunrise';
  font-style: normal;
  font-weight: 400;
  color: #4c4f6b;
  width: 100%;
  float: right;

  &__tit {
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    background: white;
  }
  &__description {
    font-family: 'Inter';
    font-size: 12px;
    line-height: 15px;
    text-transform: capitalize;
    text-align: left;
  }
  &__size {
    text-align: center;
    padding-bottom: 15px;
    font-size: 15px;
    line-height: 22px;
  }
  &__tags {
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.04em;
    text-align: center;
    padding-bottom: 15px;
  }

  &__amazon {
    width: 160px;
    background-color: #c0c3dc47;
    border-radius: 10px;
    margin-top: 5px;
    height: 40px !important;
    background-color: #c0c3dc47;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px !important;
    line-height: 22px;
    text-transform: uppercase;
  }

  &__btn {
    background: white;
    text-align: center;
  }
}
</style>
