var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xs"},[_c('div',{staticClass:"xs__page pb-2"},[_vm._v("Benefits")]),_c('div',{staticClass:"xs__col xs__text"},[_vm._v(" We love to cook and spend time in the kitchen and we understand how important every item around us is in creating an atmosphere of inspiration and comfort, and how functional and safe it should be. ")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',[_c('div',{staticClass:"xs__smart"},[_vm._v("Safe")]),_vm._m(5),_c('v-btn',{staticClass:"xs__btn pa-0",attrs:{"color":"#8287b8"},on:{"click":function($event){return _vm.$router.push('about')}}},[_vm._v("About brand"),_c('v-icon',{attrs:{"color":"white","dense":""}},[_vm._v("mdi-arrow-right")])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xs__col d-flex",staticStyle:{"line-height":"0"}},[_c('img',{staticStyle:{"overflow":"hidden","margin-right":"10px"},attrs:{"src":"img/ben-1.webp","width":"100%","height":"100%"}}),_c('img',{staticStyle:{"overflow":"hidden"},attrs:{"src":"img/ben-2.webp","width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-0 xs__row"},[_c('div',{staticClass:"xs__smart"},[_vm._v("Smart ergonomics in an exclusive design")]),_c('div',{staticClass:"xs__text"},[_c('div',{staticClass:"mb-2"},[_vm._v("We have created natural art design and beauty considering the most usable surface area")]),_c('div',{staticClass:"mb-2"},[_vm._v(" For ease of use, we have designed optimal thickness and weight parameters. Our kitchen essentials are pleasant to hold and easy to wash. We have also thought about serving dishes with sauces and a juice groove side ")]),_c('div',[_vm._v(" Fast drying and best storage – our cutting board can stand on its feet or a flat surface without a holder. Just put it on a cloth after washing and the board dries in 5 minutes and turns into an art objects ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xs__col",staticStyle:{"line-height":"0"}},[_c('img',{staticStyle:{"overflow":"hidden"},attrs:{"src":"img/ben-3.webp","width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xs__row"},[_c('div',{staticClass:"xs__smart"},[_vm._v("Practical and multifunctional")]),_c('div',{staticClass:"xs__text"},[_c('div',{staticClass:"mb-2"},[_vm._v(" Cut different foods and serve hot dishes, cured meats or pâtés, as well as cheeses and crackers or bread. You can eat straight off the boards, and they are always ready for any of your experiments. ")]),_c('div',{staticClass:"mb-2"},[_vm._v(" Decorate your table, kitchen and dining room. Add accents to your home interior with our animal-shapedboards. ")]),_c('div',[_vm._v("There is no need to have loads of various boards – you can have just one cute and loyal friend.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xs__col",staticStyle:{"line-height":"0"}},[_c('img',{staticStyle:{"overflow":"hidden"},attrs:{"src":"img/ben-4.webp","width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xs__text mb-8"},[_c('div',{staticClass:"mb-2"},[_vm._v(" The boards are made of solid premium European oak. This antibacterial wood is ideal for a long-term ownership and passing down through generations. ")]),_c('div',{staticClass:"mb-2"},[_vm._v("We cover the wood only with natural products without chemical compounds and dyes.")]),_c('div',[_vm._v("We use fully recyclable eco-friendly packaging, without colored paints, plastic and foam rubber.")])])
}]

export { render, staticRenderFns }