<template>
  <div class="footer d-flex justify-center flex-column">
    <div class="mb-5">
      <v-img class="footer__cook" src="img/svg/cookarea.svg"></v-img>
    </div>
    <div class="d-flex footer__svg">
      <div>
        <a href="https://www.amazon.com/gp/product/B0BDSRRPYM" target="_blank">
          <v-img class="mr-7 footer__img" style="margin-top: 1px" src="img/svg/amazon-fill.svg"></v-img>
        </a>
      </div>
      <div><v-img class="mr-7 footer__img" src="img/svg/inst-fill.svg"></v-img></div>
      <div><v-img class="footer__img" src="img/svg/f-fill.svg"></v-img></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'CookareaFooter',

  data() {
    return {
      img: 'cookarea.webp',
    };
  },
};
</script>

<style lang="scss">
.footer {
  height: 184px;
  background-color: #c0c3dc;
  align-items: center;

  &__img {
    width: 24px;
    height: 24px;
  }

  &__cook {
    width: 187px;
  }
  .v-image__image--cover {
    background-size: contain !important;
  }

  @media (max-width: 960px) {
    &__img {
      width: 18px;
      height: 18px;
    }
  }

  @media (max-width: 600px) {
    height: 124px;

    &__svg {
      justify-content: space-evenly;
      width: 100%;
    }
    &__img {
      width: 32px;
      height: 32px;
    }

    &__cook {
      width: 135px;
    }
  }
}
</style>
