<template>
  <div>
    <div v-if="breakpoint.xsOnly" class="back d-flex">
      <v-btn text color="#4C4F6B" class="font-weight-bold btn px-2" @click="$router.push('/')"
        ><v-icon color="#4C4F6B">mdi-arrow-left</v-icon>Back</v-btn
      >
    </div>
    <CookareaHome /> <CookareaAbout /> <CookareaFooter />
  </div>
</template>

<script>
import CookareaHome from './about/CookareaHome';
import CookareaAbout from './about/CookareaAbout';
import CookareaFooter from './home/CookareaFooter';

/* eslint-disable */
export default {
  name: 'About',

  components: { CookareaHome, CookareaAbout, CookareaFooter },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
};
</script>

<style lang="scss">
.back {
  background-color: white;
  height: 58px;
  align-items: center;
}
.btn {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
}
</style>
