<template>
  <div class="prod">
    <div>
      <div class="d-flex prod__header">
        <div class="prod__title">Get to know our products</div>
        <v-tabs
          v-model="tab"
          color="#4c4f6b"
          class="mb-5 d-flex prod__header--tabs justify-lg-end"
          background-color="#F7f7f7"
        >
          <v-tab v-for="(item, i) of tabs" :key="i" class="prod__tab">
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </div>
      <v-tabs-items v-model="tab" style="background: #f7f7f7" touchless>
        <v-tab-item v-for="(component, i) of tabs" :key="i">
          <component :is="component.component"></component>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import CatTray from '../cookareaCardTab/CatTray';
import DogTray from '../cookareaCardTab/DogTray';
import HeartCat from '../cookareaCardTab/HeartCat';
import HeartDog from '../cookareaCardTab/HeartDog';
import Bull from '../cookareaCardTab/Bull';
import Bear from '../cookareaCardTab/Bear';
import Pig from '../cookareaCardTab/Pig';

export default {
  name: 'CookareaCardTab',

  components: { CatTray, HeartCat, DogTray, HeartDog, Bull, Bear, Pig },
  data() {
    return {
      tab: 0,
      tabs: [
        {
          name: 'cat tray',
          component: 'CatTray',
        },
        {
          name: 'heart cat',
          component: 'HeartCat',
        },
        {
          name: 'dog tray',
          component: 'DogTray',
        },
        {
          name: 'heart dog',
          component: 'HeartDog',
        },
        {
          name: 'bull',
          component: 'Bull',
        },
        {
          name: 'bear',
          component: 'Bear',
        },
        {
          name: 'pig',
          component: 'Pig',
        },
      ],
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
};
</script>

<style lang="scss">
.prod {
  padding: 50px 100px 50px 100px;
  font-family: 'SangBleu Sunrise';
  font-style: normal;
  font-weight: 400;

  &__title {
    font-size: 38px;
    line-height: 56px;
    text-transform: uppercase;
    color: #4c4f6b;
  }

  &__tab {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
  }
  &__header {
    &--tabs {
      justify-content: center;
    }
  }
  @media (max-width: 1240px) {
    &__header {
      flex-direction: column;
      &--tabs {
        justify-content: start;
      }
    }
  }
  @media (max-width: 960px) {
    padding: 25px 50px 25px 50px;

    &__title {
      font-size: 28px;
      line-height: 41px;
    }
  }
}

.v-tab {
  padding: 0px !important;
  margin: 0px 8px !important;
  min-width: unset !important;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #4c4f6b !important;
  opacity: 0.4 !important;
}

.v-slide-group__next,
.v-slide-group__prev {
  min-width: 0;
}
</style>
