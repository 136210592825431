<template>
  <div class="xs">
    <div class="xs__page pb-2">Benefits</div>

    <div class="xs__col xs__text">
      We love to cook and spend time in the kitchen and we understand how important every item around us is in creating
      an atmosphere of inspiration and comfort, and how functional and safe it should be.
    </div>

    <div class="xs__col d-flex" style="line-height: 0">
      <img src="img/ben-1.webp" width="100%" height="100%" style="overflow: hidden; margin-right: 10px" />
      <img src="img/ben-2.webp" width="100%" height="100%" style="overflow: hidden" />
    </div>

    <div class="pa-0 xs__row">
      <div class="xs__smart">Smart ergonomics in an exclusive design</div>
      <div class="xs__text">
        <div class="mb-2">We have created natural art design and beauty considering the most usable surface area</div>
        <div class="mb-2">
          For ease of use, we have designed optimal thickness and weight parameters. Our kitchen essentials are pleasant
          to hold and easy to wash. We have also thought about serving dishes with sauces and a juice groove side
        </div>
        <div>
          Fast drying and best storage – our cutting board can stand on its feet or a flat surface without a holder.
          Just put it on a cloth after washing and the board dries in 5 minutes and turns into an art objects
        </div>
      </div>
    </div>
    <div class="xs__col" style="line-height: 0">
      <img src="img/ben-3.webp" width="100%" style="overflow: hidden" />
    </div>

    <div class="xs__row">
      <div class="xs__smart">Practical and multifunctional</div>
      <div class="xs__text">
        <div class="mb-2">
          Cut different foods and serve hot dishes, cured meats or pâtés, as well as cheeses and crackers or bread. You
          can eat straight off the boards, and they are always ready for any of your experiments.
        </div>
        <div class="mb-2">
          Decorate your table, kitchen and dining room. Add accents to your home interior with our animal-shapedboards.
        </div>
        <div>There is no need to have loads of various boards – you can have just one cute and loyal friend.</div>
      </div>
    </div>

    <div class="xs__col" style="line-height: 0">
      <img src="img/ben-4.webp" width="100%" style="overflow: hidden" />
    </div>

    <div>
      <div class="xs__smart">Safe</div>
      <div class="xs__text mb-8">
        <div class="mb-2">
          The boards are made of solid premium European oak. This antibacterial wood is ideal for a long-term ownership
          and passing down through generations.
        </div>
        <div class="mb-2">We cover the wood only with natural products without chemical compounds and dyes.</div>
        <div>We use fully recyclable eco-friendly packaging, without colored paints, plastic and foam rubber.</div>
      </div>

      <v-btn color="#8287b8" class="xs__btn pa-0" @click="$router.push('about')"
        >About brand<v-icon color="white" dense>mdi-arrow-right</v-icon></v-btn
      >
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'CookareaBenefits',
};
</script>

<style lang="scss">
.xs {
  font-family: 'SangBleu Sunrise';
  font-style: normal;
  font-weight: 400;
  color: #4c4f6b;

  padding: 25px 15px 25px 15px;

  &__smart {
    margin-bottom: 10px;
  }

  &__row {
    margin-bottom: 50px;
  }
  &__col {
    margin-bottom: 20px;
  }
  &__text {
    font-size: 14px;
    line-height: 19px;
  }
  &__page {
    font-size: 28px;
    line-height: 41px;
    text-transform: uppercase;
  }
  &__smart {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  &__btn {
    background-color: #8287b8;
    border-radius: 3px;
    font-family: 'Inter';
    width: 100%;
    height: 40px !important;
    font-size: 14px !important;
    line-height: 17px;
    font-style: normal;
    font-weight: 700;
  }
}
</style>
