<template>
  <div>
    <CookareaCardSlideLg
      v-if="breakpoint.xlOnly || breakpoint.lgOnly || breakpoint.mdOnly"
      :card="card"
      :items-md="itemsMd"
    />
    <CookareaCardSlideSm v-if="breakpoint.smOnly" :card="card" :items-md="itemsMd" />
  </div>
</template>

<script>
/* eslint-disable */
import CookareaCardSlideLg from '../home/CookareaCardSlideLg.vue';
import CookareaCardSlideSm from '../home/CookareaCardSlideSm.vue';

export default {
  name: 'DogTray',

  components: {
    CookareaCardSlideLg,
    CookareaCardSlideSm,
  },
  data() {
    return {
      itemsMd: [
        {
          img: 'tab-3-11.webp',
          imgCard: 'tab-3-1.webp',
        },
        {
          img: 'tab-3-12.webp',
          imgCard: 'tab-3-2.webp',
        },
        {
          img: 'tab-3-21.webp',
          imgCard: 'tab-3-3.webp',
        },
        {
          img: 'tab-3-22.webp',
          imgCard: 'tab-3-4.webp',
        },
        {
          img: 'tab-3-31.webp',
          imgCard: 'tab-3-5.webp',
        },
        {
          img: 'tab-3-322.webp',
          imgCard: 'tab-3-7.webp',
        },
      ],
      card: [
        {
          title: 'Dog tray',
          descriptionOne: 'Dimensions',
          descriptionTwo: 'Weight',
          sizeOne: '13x9x1 inches',
          sizeTwo: '1.9 pounds',
          tags: '#IdealForCarryingAndServing   #InnerRecessWithSides  #SauceCompartments #ErgonomicDesign   #Reversible   #OnFeetDrying',
        },
      ],
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
};
</script>

<style scoped lang="scss"></style>
