<template>
  <div class="prod d-flex flex-column">
    <div class="prod__page d-flex justify-end">/our product is a/</div>

    <div class="d-flex prodscrollbar">
      <div v-for="(card, i) in cards" :key="i" class="prod__card mr-2">
        <div class="mb-3">
          <div class="d-flex justify-start prod__text" :style="card.width ? 'width: 200px' : ''">{{ card.text }}</div>
          <img :src="`img/slide-img/${card.img}`" class="prod__img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'CookareaShowscase',

  data() {
    return {
      cards: [
        {
          img: 'slide-1-1.webp',
          text: 'Holiday gift',
          width: true,
        },
        {
          img: 'slide-2-1.webp',
          text: 'Functional decor piece',
        },
        {
          img: 'slide-3-1.webp',
          text: 'Perfect food presentation',
        },
        {
          img: 'slide-4-1.webp',
          text: 'Best kitchen assistant',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.prod {
  padding: 25px 15px 25px 15px;
  font-family: 'SangBleu Sunrise';
  font-style: normal;
  font-weight: 400;
  color: #4c4f6b;

  &__img {
    max-width: 497px;
  }
  &__page {
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__card {
    width: 328px;
  }

  &__text {
    font-size: 28px;
    line-height: 41px;
    text-transform: uppercase;
  }
}

.prodscrollbar {
  overflow-x: scroll;
}

.prodscrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
}

.prodscrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
