<template>
  <div>
    <CookareaCardSlideLg
      v-if="breakpoint.xlOnly || breakpoint.lgOnly || breakpoint.mdOnly"
      :card="card"
      :items-md="itemsMd"
    />
    <CookareaCardSlideSm v-if="breakpoint.smOnly" :card="card" :items-md="itemsMd" />
  </div>
</template>

<script>
/* eslint-disable */
import CookareaCardSlideLg from '../home/CookareaCardSlideLg.vue';
import CookareaCardSlideSm from '../home/CookareaCardSlideSm.vue';

export default {
  name: 'HeartDog',

  components: {
    CookareaCardSlideLg,
    CookareaCardSlideSm,
  },
  data() {
    return {
      itemsMd: [
        {
          img: 'tab-4-11.webp',
          imgCard: 'tab-4-1.webp',
        },
        {
          img: 'tab-4-12.webp',
          imgCard: 'tab-4-2.webp',
        },

        {
          img: 'tab-4-21.webp',
          imgCard: 'tab-4-3.webp',
        },
        {
          img: 'tab-4-31.webp',
          imgCard: 'tab-4-4.webp',
        },
        {
          img: 'tab-4-32.webp',
          imgCard: 'tab-4-5.webp',
        },
      ],
      card: [
        {
          title: 'Heart dog',
          descriptionOne: 'Dimensions',
          descriptionTwo: 'Weight',
          sizeOne: '11x9x1 inches',
          sizeTwo: '1.8 pounds',
          tags: '#FunctionalPrepArea  #JuiceGrooves #ErgonomicDesign   #Reversible   #OnFeetDrying',
          href: 'https://www.amazon.com/gp/product/B0BDSRRPYM',
        },
      ],
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
};
</script>

<style scoped lang="scss"></style>
