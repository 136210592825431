<template>
  <v-row class="cardSm d-flex flex-row">
    <v-col cols="7" md="7" sm="7" style="background: #f7f7f7; align-items: center" class="pa-0 py-3 d-flex">
      <agile ref="sliderMd" class="slider-card-sm" :dots="false" :nav-buttons="true" @after-change="afterChange">
        <template slot="prevButton"><v-icon color="rgb(127 130 150)" size="35">mdi-chevron-left</v-icon></template>
        <div v-for="(item, i) of itemsMd" :key="i" class="d-flex justify-center glass" @click="showDialog(item.img)">
          <v-img class="cardSm__img" :src="`img/card/${item.imgCard}`" width="78%" height="78%"></v-img>
        </div>

        <template slot="nextButton"><v-icon color="rgb(127 130 150)" size="35">mdi-chevron-right</v-icon></template>
      </agile>
    </v-col>

    <v-col cols="5" md="5" sm="5" style="background: #f7f7f7" class="pa-0 d-flex justify-end">
      <v-card v-for="(data, i) in card" :key="i" class="d-flex flex-column pa-md-9 pa-sm-3 cardSm__card">
        <div class="cardSm__title pt-3">{{ data.title }}</div>
        <div class="cardSm__description">{{ data.descriptionOne }}</div>
        <div class="cardSm__size">{{ data.sizeOne }}</div>
        <div class="cardSm__description">{{ data.descriptionTwo }}</div>
        <div class="cardSm__size">{{ data.sizeTwo }}</div>
        <div class="cardSm__tags px-4">{{ data.tags }}</div>
        <div class="d-flex justify-center">
          <v-btn
            text
            color="#4c4f6b"
            class="cardSm__amazon font-weight-bold pa-0"
            :href="data.href ? data.href : null"
            target="_blank"
            >Buy on amazon</v-btn
          >
        </div>
        <div class="cardSm__btn d-flex justify-center">
          <CarouselBtn :size="itemsMd.length" :current="currentSlide" @change="slideTo" />
        </div>
      </v-card>
    </v-col>

    <v-dialog v-model="dialog" class="image" width="unset">
      <v-card>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <span><img style="height: 55vh" :src="`img/show-card/${currentImg}`" /></span>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { VueAgile } from 'vue-agile';
import CarouselBtn from './CookareaBtnSlide';

/* eslint-disable */
export default {
  components: {
    agile: VueAgile,
    CarouselBtn,
  },

  props: {
    card: Array,
    itemsMd: Array,
  },
  name: 'CookareaCardSlideSm',

  data() {
    return {
      currentSlide: 0,
      dialog: false,
      currentImg: '',
    };
  },
  methods: {
    afterChange(current) {
      this.currentSlide = current.currentSlide;
    },
    slideTo(page) {
      this.$refs.sliderMd.goTo(page);
    },
    showDialog(value) {
      this.currentImg = value;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.currentImg = '';
    },
  },
};
</script>

<style lang="sass">
.agile
	&__nav-button
		background: transparent
		border: none
		color: #fff
		cursor: pointer
		font-size: 24px
		height: 100%
		position: absolute
		top: 0
		transition-duration: .3s
		width: 80px

		&:hover
			background-color: rgba(#838498, .5)
			opacity: 1

		&--prev
			left: 0

		&--next
			right: 0
</style>

<style scoped lang="scss">
.cardSm {
  font-family: 'SangBleu Sunrise';
  font-style: normal;
  font-weight: 400;
  color: #4c4f6b;
  width: 100%;
  float: right;

  &__card {
    position: relative;
    max-width: 261px;
    min-height: 360px;
    max-height: 385px;
  }
  &__amazon {
    width: 180px;
    background-color: #c0c3dc47;
    border-radius: 10px;
    margin-top: 5px;
    height: 40px !important;
    background-color: #c0c3dc47;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    text-transform: uppercase;
  }

  &__title {
    font-size: 20px;
    line-height: 29px;
    padding-bottom: 20px;
    text-align: center;
  }
  &__description {
    font-family: 'Inter';
    font-size: 12px;
    line-height: 15px;
    text-transform: capitalize;
    text-align: center;
    opacity: 0.3;
  }
  &__size {
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    padding-bottom: 20px;
  }
  &__tags {
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.04em;
    padding-bottom: 20px;
    text-align: center;
    letter-spacing: 0.04em;
    opacity: 0.3;
  }
  &__btn {
    position: absolute;
    bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
  @media (max-width: 730px) {
    &__img {
      width: 280px;
    }
    &__card {
      min-height: 360px;
    }
  }
  @media (max-width: 690px) {
    &__tags {
      padding-top: 10px;
    }
    &__card {
      min-height: 380px;
    }
  }
}
.glass {
  cursor: url('/img/svg/glass.svg'), auto;
}
</style>
