<template>
  <div class="carusel">
    <v-carousel
      cycle
      :interval="interval"
      :show-arrows="false"
      hide-delimiter-background
      height="100%"
      class="carusel__carusel"
    >
      <v-carousel-item v-for="(item, i) in items" :key="i" cycle eager style="height: 100%">
        <div style="height: 100%; width: 100%; display: flex">
          <img v-if="breakpoint.xsOnly" :src="`./img/${item.imgMob}`" class="carusel__carusel--img" />
          <img v-else :src="`./img/${item.img}`" class="carusel__carusel--img" />
        </div>
      </v-carousel-item>
    </v-carousel>
    <!-- контент -->
    <div class="carusel__content">
      <div
        v-if="breakpoint.xlOnly || breakpoint.lgOnly || breakpoint.mdOnly || breakpoint.smOnly"
        class="carusel__content--header d-flex"
      >
        <div class="carusel__content--text" style="color: rgb(195 191 191)">The area of inspiration</div>
        <div class="d-flex">
          <v-btn text color="rgb(195 191 191)" class="carusel__content--botton" @click="$router.push('about')"
            >About brand</v-btn
          >
          <v-btn
            text
            color="rgb(195 191 191)"
            class="carusel__content--botton"
            href="https://www.amazon.com/gp/product/B0BDSRRPYM"
            target="_blank"
            >Buy on amazon</v-btn
          >
        </div>
        <v-btn style="background-color: #8287b8" class="carusel__content--btn" @click="onHeaderClicked('form-contact')"
          >Horeca/order</v-btn
        >
      </div>
      <div v-if="!breakpoint.xsOnly" class="d-flex carusel__content--img">
        <div>
          <a href="https://www.amazon.com/gp/product/B0BDSRRPYM" target="_blank">
            <v-img
              class="mr-lg-6 mr-md-5 mr-sm-3 carusel__content--svg"
              style="margin-top: 1px"
              src="img/svg/amazon-fill.svg"
            ></v-img
          ></a>
        </div>
        <div><v-img class="mr-lg-6 mr-md-5 mr-sm-3 carusel__content--svg" src="img/svg/inst-fill.svg"></v-img></div>
        <div><v-img class="carusel__content--svg" src="img/svg/f-fill.svg"></v-img></div>
      </div>
      <div class="d-flex justify-center align-center flex-column carusel__content--title">
        <v-img class="carusel__content--cook" src="img/svg/cookarea.svg"></v-img>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'CookareaHome',

  data() {
    return {
      interval: 7000,
      items: [
        {
          img: 'home-1.webp',
          imgMob: 'home-mob-1.webp',
        },
        {
          img: 'home-2.webp',
          imgMob: 'home-mob-2.webp',
        },
        {
          img: 'home-3.webp',
          imgMob: 'home-mob-3.webp',
        },
      ],
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
  methods: {
    onHeaderClicked(form) {
      document.getElementById(form).scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss">
.carusel {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'rec1';
  .v-btn:before {
    background-color: black !important;
  }
  .v-image__image--cover {
    background-size: contain !important;
  }

  &__content {
    height: 100%;
    grid-area: rec1;
    width: 100%;
    z-index: 1;
    position: relative;
    pointer-events: none;

    &--img {
      position: absolute;
      bottom: 50px;
      left: 100px;
    }

    &--header {
      height: 70px;
      background-color: rgb(255 255 255 / 33%);
      align-items: center;
      justify-content: space-evenly;
      position: fixed;
      width: 100%;
    }

    &--botton {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;

      color: rgb(76, 79, 107);
      text-transform: uppercase;
    }
    &--text {
      font-family: 'SangBleu Sunrise';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      text-transform: uppercase;
      color: rgb(76, 79, 107);
    }

    &--btn {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      border-radius: 70px !important;
      width: 253px;
    }

    &--title {
      margin-top: 415px;
      margin-bottom: 415px;
    }

    &--cook {
      width: 396px;
    }

    &--svg {
      width: 24px;
      height: 24px;
    }

    @media (max-width: 1240px) {
      &--title {
        margin-top: 390px;
        margin-bottom: 390px;
      }
    }
    @media (max-width: 960px) {
      max-height: 480px;
      min-height: 480px;
      &--title {
        margin-top: 220px;
      }
      &--img {
        left: 50px;
        bottom: 35px;
      }
      &--header {
        height: 54px;
      }
      &--cook {
        width: 217px;
      }
      &--btn {
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        width: 158px;
        height: 34px !important;
      }
      &--botton {
        font-size: 14px;
        line-height: 17px;
      }
      &--text {
        font-size: 12px;
        line-height: 18px;
      }
      &--svg {
        width: 18px;
        height: 18px;
      }
    }
    @media (max-width: 800px) {
      &--header {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    @media (max-width: 600px) {
      width: 100%;
      min-height: auto;
      &--cook {
        width: 270px;
      }
      &--title {
        margin-top: 50%;
      }
    }

    @media (max-width: 400px) {
      max-height: 420px;
    }
  }

  &__content > * {
    pointer-events: auto;
  }

  &__carusel {
    grid-area: rec1;
    width: 100%;
    height: auto;
    z-index: 0;
    display: inline-block;
    .v-btn {
      width: 15px !important;
      height: 15px !important;
      overflow: hidden;
    }
    .v-btn--active {
      color: #f7f7f7 !important;
      border-radius: 50px;
      background-color: #f7f7f7 !important;
      width: 40px !important;
    }

    .theme--dark.v-btn--active::before {
      color: #f7f7f7 !important;
      border-radius: 50px;
      background-color: #f7f7f7 !important;
      width: 40px !important;
    }

    &--img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      filter: brightness(0.7);
    }
  }

  .v-carousel__controls {
    height: 65px;
  }

  @media (max-width: 960px) {
    .v-carousel__controls {
      align-items: flex-start;
      height: 35px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    &__carusel {
      &--img {
        position: inherit;
      }
    }
  }
  @media (max-width: 400px) {
    &__carusel {
      &--img {
        height: 420px;
      }
    }
  }
  .v-carousel__controls {
    align-items: flex-start;
    height: 40px;
  }
}
</style>
