<template>
  <div class="steps">
    <div class="steps__page d-flex">/production steps/</div>
    <v-row class="steps__all d-flex">
      <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="steps__video px-0">
        <div class="d-flex justify-center">
          <iframe
            class="steps__video--youtube"
            src="https://www.youtube.com/embed/Ysc0PiYDFzM"
            title="Cookarea inspo"
            frameborder="0"
            style="background: #f7f7f7"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
      </v-col>
      <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="steps__content d-flex py-3">
        <div v-for="(data, i) in steps" :key="i" class="steps__content--hr">
          <div>
            <hr style="border: none; border-top: 1px solid #4c4f6b" />
            <div class="steps__content--list d-flex">
              <div class="steps__content--numbers mr-3">{{ data.number }}</div>
              <div class="steps__content--text">{{ data.text }}</div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col v-if="breakpoint.xlOnly || breakpoint.lgOnly" cols="4" class="d-flex justify-center steps__content--img">
        <img src="img/steps.webp" class="steps__content--stepImg"
      /></v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'CookareaSteps',

  data() {
    return {
      steps: [
        {
          number: '1',
          text: 'Choosing lumber',
        },
        {
          number: '2',
          text: 'Careful selection of materials and tools ',
        },
        {
          number: '3',
          text: 'Composing edge glued panels',
        },
        {
          number: '4',
          text: 'Hand-crafting graceful elements',
        },
        {
          number: '5',
          text: 'Manual sanding of boards',
        },
        {
          number: '6',
          text: 'Inserting the logo',
        },
        {
          number: '7',
          text: 'Oil and wax treatment in several stages',
        },
        {
          number: '8',
          text: 'Drying, quality control and packaging',
        },
      ],
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
};
</script>

<style lang="scss">
.steps {
  padding: 50px 100px 50px 100px;
  font-family: 'SangBleu Sunrise';
  font-style: normal;
  font-weight: 400;
  color: #4c4f6b;

  &__page {
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    margin-bottom: 50px;
  }

  &__video {
    width: 100%;
    &--youtube {
      width: 402px;
      height: 714px;
    }
  }

  &__content {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    flex-wrap: wrap;
    align-content: center;

    &--stepImg {
      height: 714px;
    }
    &--hr {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
    &--divider {
      margin-bottom: 11px;
      color: #4c4f6b;
      background-color: #4c4f6b;
    }
    &--list {
      overflow: hidden;
    }
    &--numbers {
      font-size: 38px;
      line-height: 56px;
      text-transform: uppercase;
    }
    &--text {
      font-size: 18px;
      line-height: 26px;
      text-align: right;
      margin-left: auto;
    }
    @media (max-width: 1400px) {
      &--stepImg {
        width: 376px;
        height: 668px;
      }
      &--text {
        width: 320px;
      }
    }
    @media (max-width: 1300px) {
      &--stepImg {
        width: 368px;
        height: 654px;
      }
    }
  }

  @media (max-width: 1400px) {
    &__video {
      width: 100%;
      &--youtube {
        width: 376px;
        height: 668px;
      }
    }
  }

  @media (max-width: 1300px) {
    &__video {
      &--youtube {
        width: 368px;
        height: 654px;
      }
    }
  }
  @media (max-width: 1263px) {
    &__content {
      align-content: flex-end;
    }
  }
  @media (max-width: 1240px) {
    &__video {
      &--youtube {
        width: 337px;
        height: 600px;
      }
    }
    &__content {
      &--hr {
        width: 100%;
      }

      &--text {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
      }
      &--numbers {
        font-size: 18px;
        line-height: 26px;
      }
      &--divider {
        margin-bottom: 3px;
      }
    }
  }

  @media (max-width: 960px) {
    padding: 25px 50px 25px 50px;
    &__video {
      &--youtube {
        width: 280px;
        height: 497px;
      }
    }
    &__page {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 20px;
    }
    &__all {
      flex-wrap: wrap;
    }

    &__content {
      flex-direction: column;

      &--text {
        font-size: 14px;
        line-height: 19px;
      }
      &--numbers {
        font-size: 18px;
        line-height: 26px;
      }
      &--divider {
        margin-right: 0px;
        margin-bottom: 5px;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 25px 15px 25px 15px;
    &__page {
      justify-content: end;
    }
    &__video {
      margin-bottom: 20px;
    }
    &__content {
      padding-left: 15px;
      padding-right: 15px;
      &--hr {
        padding: 0;
        padding-bottom: 5px;
      }
    }
  }
}

.video-js .vjs-control-bar {
  width: 74% !important;

  left: 13% !important;
}
.video-js {
  background-color: #f7f7f7 !important;
}
.video-js .vjs-big-play-button {
  left: 16%;
}
.vjs-poster {
  background-color: #f7f7f7 !important;
}
</style>
