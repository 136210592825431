<template>
  <div class="showcase d-flex flex-column">
    <div class="showcase__page d-flex">/Product showcase/</div>

    <ScrollShadow>
      <div class="d-flex">
        <div v-for="(card, i) in cards" :key="i">
          <v-card class="showcase__card text-center mb-2" style="max-width: 406px">
            <div class="showcase__title d-flex justify-center">{{ card.title }}</div>
            <div class="showcase__img"><img :src="`img/card/${card.img}`" width="70%" /></div>
          </v-card>
        </div>
      </div>
    </ScrollShadow>
  </div>
</template>

<script>
/* eslint-disable */
import ScrollShadow from '../ScrollShadow.vue';

export default {
  name: 'CookareaShowscase',
  components: {
    ScrollShadow,
  },
  data() {
    return {
      cards: [
        {
          img: 'tab-1-1.webp',
          title: 'Cat tray',
        },
        {
          img: 'tab-4-1.webp',
          title: 'Heart dog',
        },
        {
          img: 'tab-5-1.webp',
          title: 'Bull',
        },
        {
          img: 'tab-6-1.webp',
          title: 'Bear',
        },
        {
          img: 'tab-2-1.webp',
          title: 'Heart cat',
        },
        {
          img: 'tab-3-1.webp',
          title: 'Dog tray',
        },
        {
          img: 'tab-7-1.webp',
          title: 'Pig',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.showcase {
  padding: 100px 100px 50px 100px;
  font-family: 'SangBleu Sunrise';
  font-style: normal;
  font-weight: 400;

  &__title {
    font-size: 28px;
    line-height: 41px;
    padding-top: 35px;
  }
  &__img {
    height: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__page {
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    margin-bottom: 50px;
  }

  &__card {
    width: 328px;
    height: 406px;
    margin-right: 30px;
  }

  @media (max-width: 960px) {
    padding: 50px 50px 25px 50px;

    &__page {
      font-size: 12px;
      line-height: 18px;
      padding: 0px;
      margin-bottom: 20px;
    }
    &__card {
      width: 242px;
      height: 288px;
      margin-right: 10px;
    }
    &__title {
      padding-top: 20px;
    }
    &__img {
      height: 227px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media (max-width: 600px) {
    padding: 50px 15px 25px 15px;

    &__page {
      justify-content: end;
    }
  }
}
</style>

<style lang="scss">
.showscrollbar {
  overflow-x: scroll;
  overflow-y: hidden;
}

.showscrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 8px;
}

.showscrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);

  background-color: #86879a;
}
</style>
