<template>
  <div class="d-flex flex-row">
    <button
      v-for="index in size"
      :key="index"
      class="cardot"
      :class="{ 'cardot--current': index - 1 === current }"
      @click="$emit('change', index - 1)"
    />
  </div>
</template>

<script>
export default {
  name: 'CookareaBtnSlide',

  props: {
    current: { type: Number, required: true, default: 0 },
    size: { type: Number, required: true },
  },
};
</script>

<style scoped lang="scss">
.cardot {
  border-style: solid;
  border-width: 1px;
  border-color: #4c4f6b;
  background-color: #4c4f6b;
  opacity: 0.3;
  margin: 0 5px;
  border-radius: 100px;
  cursor: pointer;
  height: 15px;
  width: 15px;
  transition-duration: 0.3s;

  &:hover {
    background-color: rgb(201, 201, 201);
  }

  &--current {
    width: 45px;
    background-color: #4c4f6b;
    opacity: 0.7;
  }
}
</style>
