<template>
  <v-row class="card d-flex flex-row">
    <v-col cols="8" lg="8" md="8" style="background: #f7f7f7; align-items: center" class="pa-0 d-flex">
      <agile
        ref="slider"
        class="slider-card"
        :dots="false"
        :nav-buttons="true"
        :slides-to-show="2"
        @after-change="afterChange"
      >
        <template slot="prevButton"><v-icon color="#f7f7f7" size="35">mdi-chevron-left</v-icon></template>

        <div v-for="(item, i) of itemsMd" :key="i">
          <div class="mx-2 glass" style="line-height: 0" @click="showDialog(item.img)">
            <v-img :src="`img/card/${item.imgCard}`" width="100%" height="100%" />
          </div>
        </div>
        <template slot="nextButton"><v-icon color="#f7f7f7" size="35">mdi-chevron-right</v-icon></template>
      </agile>
    </v-col>

    <v-col cols="4" lg="4" md="4" style="background: #f7f7f7" class="pa-0 d-flex justify-end">
      <v-card v-for="(data, i) in card" :key="i" class="d-flex flex-column pa-lg-9 pa-md-9 pa-sm-4 card__card">
        <div class="card__title">{{ data.title }}</div>
        <div class="card__description">{{ data.descriptionOne }}</div>
        <div class="card__size">{{ data.sizeOne }}</div>
        <div class="card__description">{{ data.descriptionTwo }}</div>
        <div class="card__size">{{ data.sizeTwo }}</div>
        <div class="card__tags">{{ data.tags }}</div>
        <div class="d-flex justify-center">
          <v-btn
            text
            color="#4c4f6b"
            class="card__amazon font-weight-bold pa-0"
            :href="data.href ? data.href : null"
            target="_blank"
            >Buy on amazon</v-btn
          >
        </div>
        <div class="card__btn d-flex justify-center">
          <CarouselBtn :size="itemsMd.length" :current="currentSlide" @change="slideTo" />
        </div>
      </v-card>
    </v-col>

    <v-dialog v-model="dialog" class="image" width="unset">
      <v-card>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <span><img class="card__show" :src="`img/show-card/${currentImg}`" /></span>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { VueAgile } from 'vue-agile';
import CarouselBtn from './CookareaBtnSlide';

/* eslint-disable */
export default {
  components: {
    agile: VueAgile,
    CarouselBtn,
  },

  props: {
    card: Array,
    // items: Array,
    itemsMd: Array,
  },
  name: 'CookareaCardSlideLg',

  data() {
    return {
      currentSlide: 0,
      dialog: false,
      currentImg: '',
    };
  },
  methods: {
    afterChange(current) {
      this.currentSlide = current.currentSlide;
    },
    slideTo(page) {
      this.$refs.slider.goTo(page);
    },
    showDialog(value) {
      this.currentImg = value;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.currentImg = '';
    },
  },
};
</script>

<style lang="sass">
.agile
	&__nav-button
		background: transparent
		border: none
		color: #fff
		cursor: pointer
		font-size: 24px
		height: 100%
		position: absolute
		top: 0
		transition-duration: .3s
		width: 80px

		&:hover
			background-color: rgba(#838498, .5)
			opacity: 1

		&--prev
			left: 0

		&--next
			right: 0
</style>

<style lang="scss">
.agile {
  width: 100%;
}

.card {
  font-family: 'SangBleu Sunrise';
  font-style: normal;
  font-weight: 400;
  color: #4c4f6b;
  width: 100%;
  float: right;

  &__card {
    position: relative;
    max-width: 332px;
    max-height: 507px;
    min-height: 507px;
  }
  &__show {
    height: 75vh;
  }
  &__amazon {
    width: 200px;
    background-color: #c0c3dc47;
    border-radius: 10px;
    margin-top: 15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
  }
  &__title {
    font-size: 28px;
    line-height: 41px;
    text-align: center;
    padding-bottom: 50px;
    color: #4c4f6b;
  }
  &__description {
    font-family: 'Inter';
    font-size: 15px;
    line-height: 18px;
    text-transform: capitalize;
    text-align: center;
    color: #4c4f6b;
    opacity: 0.3;
  }
  &__size {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    padding-bottom: 20px;
    color: #4c4f6b;
  }
  &__tags {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.04em;
    padding: 10px 0;
    color: #4c4f6b;
    opacity: 0.3;
  }

  &__btn {
    position: absolute;
    bottom: 35px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
  @media (max-width: 1240px) {
    &__card {
      max-width: 300px;
      max-height: 450px;
    }
    &__show {
      height: 65vh;
    }
  }
  @media (max-width: 1100px) {
    &__card {
      position: relative;
      max-width: 332px;
      max-height: 450px;
      min-height: 450px;
    }
    &__title {
      padding-bottom: 25px;
    }
    &__show {
      height: 60vh;
    }
  }
}

.glass {
  cursor: url('/img/svg/glass.svg'), auto;
}
</style>
