<template>
  <div class="materials">
    <div class="materials__page d-flex">/Materials/</div>

    <div class="d-flex flex-wrap">
      <v-row>
        <v-col v-for="(data, i) in materials" :key="i" cols="12" lg="3" md="6" sm="6" xs="12">
          <div v-if="breakpoint.xsOnly" class="materials__title mb-2">{{ data.title }}</div>
          <img :src="`./img/${data.img}`" width="100%" />
          <div
            v-if="breakpoint.xlOnly || breakpoint.lgOnly || breakpoint.mdOnly || breakpoint.smOnly"
            class="materials__title mt-lg-6 mb-lg-2 mt-md-4 mt-sm-4"
          >
            {{ data.title }}
          </div>
          <div
            class="materials__text mt-2"
            :class="(breakpoint.mdOnly || breakpoint.smOnly) && data.isMb ? 'mb-6' : ''"
          >
            <div>{{ data.text_1 }}</div>
            <div>{{ data.text_2 }}</div>
            <div>{{ data.text_3 }}</div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'CookareaMaterials',

  data() {
    return {
      materials: [
        {
          img: 'mat-1.webp',
          isMb: true,
          title: 'Natural antibacterial oak',
          text_1:
            'All the boards are made of premium traditional European oak which is considered to be one of the most antibacterial materials in the world.',
        },
        {
          img: 'mat-2.webp',
          title: 'Durability',
          isMb: true,
          text_1: 'Our cutting boards are quite tough, they are ready to go under the knife every day.',
          text_2: 'Oak products and products are popular and traditional in many countries.',
          text_3:
            'They are passed down from generation to generation and bring harmony and natural warmth to the house.',
        },
        {
          img: 'mat-3.webp',
          title: 'Knife friendly',
          text: 'After our quality woodworks the surface of the oak board becomes elastic and slippery.',
          text_2:
            'Natural and non-toxic wax gives the wood a silky smooth feel to the touch, making our boards to be the gentlest on a knife blade.',
          text_3: 'Originally the oak itself is not as hard as plastic, bamboo or maple which ruin your knives.',
        },
        {
          img: 'mat-4.webp',
          title: 'Protective and moisture-resistant coating',
          text_1:
            'The wood is treated several times with natural oil and wax and additionally polished to fix the composition in the fibers.',
        },
      ],
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
};
</script>

<style lang="scss">
.materials {
  padding: 50px 100px 50px 100px;
  font-family: 'SangBleu Sunrise';
  font-style: normal;
  font-weight: 400;
  color: #4c4f6b;

  &__page {
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    margin-bottom: 50px;
  }
  &__title {
    font-size: 28px;
    line-height: 41px;
  }
  &__text {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: 960px) {
    padding: 25px 50px 25px 50px;

    &__page {
      margin-bottom: 20px;
    }
    &__page,
    &__text {
      font-size: 14px;
      line-height: 19px;
    }
    &__title {
      font-size: 18px;
      line-height: 26px;
    }
  }
  @media (max-width: 600px) {
    padding: 25px 15px 25px 15px;

    &__page {
      justify-content: end;
      margin-bottom: 8px;
    }
  }
}
</style>
